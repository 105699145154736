import React from 'react';
import { Progress } from 'semantic-ui-react';

export default class PieChart extends React.Component {
  UNSAFE_componentWillMount() {
    this.setScreenSize();
    window.addEventListener('resize', () => this.setScreenSize());
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.setScreenSize());
  }

  setScreenSize = () => {
    const screenSize = window.innerWidth < 991 ? 'small' : 'large';
    this.setState({ screenSize });
  }

  desktopCar = (color) => (
    <svg width="50" height="29" viewBox="0 0 50 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.7514 24.8571H4.52913C4.52913 24.8571 0.445801 24.8571 0.445801 19.3333C0.445801 16.5714 0.445801 11.0476 1.80691 9.66667C3.16802 8.28571 12.6958 0 16.7791 0H24.9458C29.0291 0 38.5569 9.66667 38.5569 9.66667L44.0014 11.0476C44.0014 11.0476 48.0847 12.4286 48.0847 15.1905V19.3333C48.0847 19.3333 49.4458 19.8001 49.4458 22.0359C49.4458 24.8571 46.7236 24.8571 46.7236 24.8571H31.7514Z" fill={color} />
      <path d="M22.2236 2.7619H24.9458C27.668 2.7619 35.8347 11.0476 35.8347 11.0476C35.8347 11.0476 29.0332 10.6844 22.2236 10.3309V2.7619ZM8.61249 6.90476C9.9736 5.52381 15.418 2.7619 18.1403 2.7619H19.5014V10.19C14.042 9.90833 9.18144 9.66666 8.61249 9.66666C7.25138 9.66666 7.25138 8.28571 8.61249 6.90476Z" fill="#BBDDF5" />
      <path d="M35.8349 29C32.828 29 30.3904 26.5269 30.3904 23.4762C30.3904 20.4255 32.828 17.9524 35.8349 17.9524C38.8417 17.9524 41.2793 20.4255 41.2793 23.4762C41.2793 26.5269 38.8417 29 35.8349 29Z" fill="#292F33" />
      <path d="M35.8347 26.2381C34.3312 26.2381 33.1124 25.0016 33.1124 23.4762C33.1124 21.9508 34.3312 20.7143 35.8347 20.7143C37.3381 20.7143 38.5569 21.9508 38.5569 23.4762C38.5569 25.0016 37.3381 26.2381 35.8347 26.2381Z" fill="#CCD6DD" />
      <path d="M12.6959 29C9.68905 29 7.25149 26.5269 7.25149 23.4762C7.25149 20.4255 9.68905 17.9524 12.6959 17.9524C15.7028 17.9524 18.1404 20.4255 18.1404 23.4762C18.1404 26.5269 15.7028 29 12.6959 29Z" fill="#292F33" />
      <path d="M12.696 26.2381C11.1925 26.2381 9.97377 25.0016 9.97377 23.4762C9.97377 21.9508 11.1925 20.7143 12.696 20.7143C14.1994 20.7143 15.4182 21.9508 15.4182 23.4762C15.4182 25.0016 14.1994 26.2381 12.696 26.2381Z" fill="#CCD6DD" />
    </svg>
  )

  mobileCar = (color) => (
    <svg width="29" height="17" viewBox="0 0 29 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.292 13.9885H3.05392C3.05392 13.9885 0.768208 13.9885 0.768208 10.9409C0.768208 9.41704 0.768208 6.36942 1.53011 5.60752C2.29202 4.84561 7.62535 0.274185 9.91106 0.274185H14.4825C16.7682 0.274185 22.1015 5.60752 22.1015 5.60752L25.1492 6.36942C25.1492 6.36942 27.4349 7.13133 27.4349 8.65514V10.9409C27.4349 10.9409 28.1968 11.1984 28.1968 12.4319C28.1968 13.9885 26.673 13.9885 26.673 13.9885H18.292Z" fill={color} />
      <path d="M12.9586 1.79799H14.4824C16.0062 1.79799 20.5776 6.36942 20.5776 6.36942C20.5776 6.36942 16.7704 6.16904 12.9586 5.97399V1.79799ZM5.33954 4.0837C6.10145 3.3218 9.14907 1.79799 10.6729 1.79799H11.4348V5.89627C8.37878 5.74085 5.65802 5.60751 5.33954 5.60751C4.57764 5.60751 4.57764 4.84561 5.33954 4.0837V4.0837Z" fill="#BBDDF5" />
      <path d="M20.5776 16.2742C18.8945 16.2742 17.53 14.9097 17.53 13.2266C17.53 11.5434 18.8945 10.1789 20.5776 10.1789C22.2608 10.1789 23.6252 11.5434 23.6252 13.2266C23.6252 14.9097 22.2608 16.2742 20.5776 16.2742Z" fill="#292F33" />
      <path d="M20.5778 14.7504C19.7362 14.7504 19.0539 14.0681 19.0539 13.2266C19.0539 12.385 19.7362 11.7028 20.5778 11.7028C21.4193 11.7028 22.1016 12.385 22.1016 13.2266C22.1016 14.0681 21.4193 14.7504 20.5778 14.7504Z" fill="#CCD6DD" />
      <path d="M7.62548 16.2742C5.94232 16.2742 4.57786 14.9097 4.57786 13.2266C4.57786 11.5434 5.94232 10.1789 7.62548 10.1789C9.30863 10.1789 10.6731 11.5434 10.6731 13.2266C10.6731 14.9097 9.30863 16.2742 7.62548 16.2742Z" fill="#292F33" />
      <path d="M7.62512 14.7504C6.78354 14.7504 6.10131 14.0681 6.10131 13.2266C6.10131 12.385 6.78354 11.7028 7.62512 11.7028C8.46669 11.7028 9.14893 12.385 9.14893 13.2266C9.14893 14.0681 8.46669 14.7504 7.62512 14.7504Z" fill="#CCD6DD" />
    </svg>
  )

  handleColor = (percentage) => {
    if (percentage <= 25) return { color: '#E75353', class: 'red' };
    if (percentage >= 26 && percentage <= 60) return { color: '#F2C617', class: 'yellow' };
    return { color: '#74B816', class: 'green' };
  }

  render() {
    const { percentage } = this.props;
    const colorConfig = this.handleColor(percentage);

    let car = this.mobileCar;
    let carWidth = 29;

    if (this.state.screenSize === 'large') {
      car = this.desktopCar;
      carWidth = 57;
    }

    let carPosition = 0;
    if (this.state.containerRef) {
      const containerWidth = this.state.containerRef.offsetWidth;
      const coloredBarWidth = (containerWidth * percentage) / 100;

      if (coloredBarWidth > carWidth) {
        carPosition = coloredBarWidth - carWidth;
      }
    }

    return (
      <div style={{ flex: 1 }}>
        <div
          ref={(containerRef) => { if (!(this.state && this.state.containerRef)) this.setState({ containerRef }); }}
          style={{ paddingLeft: carPosition }}
        >
          {car(colorConfig.color)}
        </div>

        <Progress className={'ui progress ' + colorConfig.class} percent={percentage} height={6} />
      </div>
    );
  }
}
