/*eslint prefer-const: off*/
/*eslint-env es6*/
import React from 'react';

// Libraries
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { Icon, Popup, Loader } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import qs from 'qs';

// Components
import Avatar from '../../components/Avatar';
import Page from '../../components/Page';
import Paginator from '../../components/Paginator';
import Table from '../../components/Table';

// Others
import { __ } from '../../i18n';
import * as utils from '../../utils';

const PAGE_SIZE = 40;

const getDateInterval = (startDate, endDate) => {
  const offset = endDate ? moment().diff(endDate, 'days') : moment().diff(startDate, 'days');
  const limit = startDate ?
    moment().diff(startDate, 'days')
    : offset + 7;
  return {
    column: 'CREATED',
    interval: 'DAY',
    offset,
    limit
  };
};

const organizationEntitySearchQuery = gql`
  query OrganizationEntitySearchQuery($id: ID!, $search: String, $limit: Int, $type: [EntityType!]) {
  node (id: $id) {
    ... on Organization{
      id: dbId
      entities(search: $search, limit: $limit, type: $type, getAllEntities: true) {
        nodes {
          id: dbId
          fullname
          picture {
            uri
            id: dbId
            key
          }
        }
      }
    }
  }
}`;

const OrganizationArrivedQuery = gql`query OrganizationArrivedQuery (
  $organizationId: ID!,
  $limit: Int,
  $offset: Int,
  $orderBy: [OrganizationArrivalsOrder],
  $dateInterval: OrganizationArrivalsDateInterval,
  $conciergeIds: [ID!],
  $entityIds: [ID!],
  $status: [ArrivalStatus],
  $duration: [OrganizationArrivalsDurationInput!],
  ) {
  node(id: $organizationId) @connection(key: "Organization", filter: ["organizationId"]) {
    ... on Organization {
      id: dbId
      arrivedConfiguration {
        id: dbId
        concierges {
          nodes {
            id: dbId
            name
          }
        }
      }
      arrivals(offset: $offset, limit: $limit, orderBy: $orderBy, dateInterval: $dateInterval, conciergeIds: $conciergeIds, entityIds: $entityIds, status: $status, listAll: true, duration:$duration){
        nodes {
          id: dbId
          status
          startDate
          endDate
          entity {
            id: dbId
            fullname
            picture {
              uri (size: "s70")
              id: dbId
              key
            }
            roles {
              nodes {
                id: dbId
                name
              }
            }
          }
          concludedUser {
            id: dbId
             fullname
          }
          acceptedUser {
            id: dbId
             fullname
          }
          user {
            id: dbId
            fullname
            tags {
              nodes {
                id
                name
              }
            }
          }
          concierge {
            id: dbId
            name
          }
          vehicle {
            id: dbId
            brand
            model
            licensePlate
          }
        }
        totalCount
      }
    }
  }
}
  `;

const handleDuration = (durationOptions) => {
  //duration in seconds
  const _10Min = 600;
  const _05Min = 300;
  // 1- more than 10 minutes
  // 2- more than 5 minutes
  // 3- less than 5 minutes
  const moreThan10 = durationOptions.indexOf(1) !== -1;
  const moreThan5 = durationOptions.indexOf(2) !== -1;
  const lessThan5 = durationOptions.indexOf(3) !== -1;

  let formatedDuration = {};
  if (durationOptions.length === 1) {
    if (moreThan10) formatedDuration = [{ minimum: _10Min }];
    if (moreThan5) formatedDuration = [{ minimum: _05Min, maximum: _10Min - 1 }];
    if (lessThan5) formatedDuration = [{ minimum: 0, maximum: _05Min - 1 }];
  } else if (lessThan5 && !moreThan5 && moreThan10) formatedDuration = [{ minimum: 0, maximum: _05Min - 1 }, { minimum: _10Min }];
  else if (!lessThan5 && moreThan5 && moreThan10) formatedDuration = [{ minimum: _05Min }];
  else if (lessThan5 && moreThan5 && !moreThan10) formatedDuration = [{ minimum: 0, maximum: _10Min - 1 }]; else formatedDuration = [{ minimum: 0 }];

  return formatedDuration;
};

@inject('store', 'api', 'client')
export default class ArrivedHistory extends Page {
  constructor(props) {
    super(props);
    this.colNames = [__('Date'), __('Student'), __('Responsible'), __('Transport'), __('Gate'), __('Handled by'), ''];
    const { location } = props;
    const query = { ...location.query };

    const { startDate, endDate } = this.getDatesFromQuery(query);
    const order = this.getOrderFromQuery(query);
    const conciergeIds = this.getConciergeIdsFromQuery(query);

    this.state = {
      concierges: [],
      order,
      conciergeIds,
      startDate,
      endDate,
      arrivalStatus: [
        { value: 'completed', text: __('Completed'), selected: false },
        { value: 'expired', text: __('Expired'), color: '#999999', selected: false },
        { value: 'canceled', text: __('Cancelled'), color: '#E5A899', selected: false },
      ],
      arrivalWaitTime: [
        {
          id: 1,
          color: 'DE4E47',
          title: __('More than 10 minutes'),
          selected: false,
        },
        {
          id: 2,
          color: 'F2C617',
          title: __('More than 5 minutes'),
          selected: false,
        },
        {
          id: 3,
          color: '74B816',
          title: __('Less than 5 minutes'),
          selected: false,
        }
      ],
      invalidFilters: false
    };
  }

  /**
   * @type {{
   *  text: string,
   *  value: string
   * }}
   */
  orderOptions = [
    { text: __('Student A-Z'), value: '{"column":"ENTITY_FULLNAME","direction":"ASC"}' },
    { text: __('Student Z-A'), value: '{"column":"ENTITY_FULLNAME","direction":"DESC"}' },
    { text: __('Oldest first'), value: '{"column":"CREATED","direction":"ASC"}' }
  ]

  dateFilterDefaultInterval = {
    startDate: moment().subtract(1, 'week').format('YYYY-MM-DD HH:mm:ss'),
    endDate: moment().format('YYYY-MM-DD HH:mm:ss')
  };

  /**
   * @param {{
   *  startDate: string,
   *  endDate: string
   * }} query
   * @returns {{
   *  startDate: string,
   *  endDate: string
   * }}
   */
  getDatesFromQuery = (query) => {
    const { startDate: queryStartDate, endDate: queryEndDate } = query || {};


    const diffBetweenDates = moment(queryStartDate).diff(queryEndDate, 'months');
    const invalidParametersDetected = diffBetweenDates > 2 || diffBetweenDates < 0;
    let startDate;
    let endDate;
    if (invalidParametersDetected || !queryStartDate) {
      startDate = this.dateFilterDefaultInterval.startDate;
      endDate = this.dateFilterDefaultInterval.endDate;
    } else {
      startDate = queryStartDate;
      endDate = queryEndDate;
    }

    return { startDate, endDate };
  }

  /**
   * @param {{
   *  orderBy: string
   * }} query
   * @returns {{
   *  value: string,
   *  text: string,
   *  selected: boolean
   * }}
   */
  getOrderFromQuery = query => this.orderOptions.map(e => ({ value: e.value, text: e.text, selected: query.orderBy === e.value }))

  /**
   * @param {{
   *  conciergeIds: string
   * }} query
   * @returns {String[]}
   */
  getConciergeIdsFromQuery = query => (query.conciergeIds && JSON.parse(query.conciergeIds)) || []

  /**
  * @params (required) object with informations about filter {
  *     filterBy: string or object, that indicate the filter item selected
  *     key: string, that indicate what filter is
  *   }
  **/
  simpleFiltersCallback = (params) => {
    const keys = {
      order: 'orderBy',
      concierges: 'conciergeIds',
      date: 'date',
      status: 'status',
      waitTime: 'duration',
    };
    const getNewState = {
      order: value => (prevState) => {
        const index = prevState.order.findIndex(x => isEqual(x.value, value));
        const order = prevState.order.map(x => ({ ...x, selected: false }));
        if (index !== -1) order[index].selected = true;
        return { order };
      },
      concierges: value => (prevState) => {
        let newconciergeIds;
        let newConcierges = [...prevState.concierges];

        if (prevState.conciergeIds.includes(value)) {
          newconciergeIds = prevState.conciergeIds.filter(id => id !== value);
        } else {
          newconciergeIds = [...prevState.conciergeIds, value];
        }

        const index = prevState.concierges.findIndex(c => c.value === value);
        newConcierges[index].selected = !newConcierges[index].selected;

        const nextState = {
          concierges: newConcierges,
          conciergeIds: newconciergeIds
        };
        return nextState;
      },
      status: value => (prevState) => {
        let newArrivals = [...prevState.arrivalStatus];
        const index = newArrivals.findIndex(arrival => arrival.value === value);
        if (index !== -1) newArrivals[index].selected = !newArrivals[index].selected;
        const nextState = {
          arrivalStatus: newArrivals
        };
        return nextState;
      },
      waitTime: value => (prevState) => {
        let newArrivalWaitTime = [...prevState.arrivalWaitTime];
        const index = newArrivalWaitTime.findIndex(waitTimeOption => waitTimeOption.id === value);
        if (index !== -1) newArrivalWaitTime[index].selected = !newArrivalWaitTime[index].selected;
        const nextState = {
          arrivalWaitTime: newArrivalWaitTime
        };
        return nextState;
      },
      date: value => () => ({
        startDate: value.startDate,
        endDate: value.endDate
      })
    };

    let value = params.filterBy;
    const key = params.key;

    this.setState({ loading: true });

    if (key === 'concierges') {
      const { conciergeIds: rawconciergeIds } = this.props.location.query;
      const conciergeIds = rawconciergeIds && JSON.parse(rawconciergeIds);

      let newconciergeIds;
      if (!conciergeIds) newconciergeIds = [value];
      else if (conciergeIds.includes(value)) newconciergeIds = conciergeIds.filter(id => id !== value);
      else newconciergeIds = [...conciergeIds, value];

      this.onMultipleParameterChange('conciergeIds', newconciergeIds);
    } else if (key === 'status') {
      const { status: rawStatus } = this.props.location.query;
      const status = rawStatus && JSON.parse(rawStatus);
      let newStatus;
      if (!status) newStatus = [value];
      else if (status.includes(value.toUpperCase())) newStatus = status.filter(statusItem => statusItem !== value.toUpperCase());
      else newStatus = [...status, value];

      if (newStatus) newStatus = newStatus.map(statusItem => statusItem.toUpperCase());

      this.onMultipleParameterChange('status', newStatus);
    } else if (key === 'waitTime') {
      const { duration: rawWaitTime } = this.props.location.query;
      const waitTime = rawWaitTime && JSON.parse(rawWaitTime);
      let newWaitTime;
      if (!waitTime) newWaitTime = [value];
      else if (waitTime.includes(value)) newWaitTime = waitTime.filter(waitTimeOption => waitTimeOption !== value);
      else newWaitTime = [...waitTime, value];


      this.onMultipleParameterChange('duration', newWaitTime);
    } else if (key === 'date') {
      const parameters = [
        {
          name: 'startDate',
          value: value.startDate
        },
        {
          name: 'endDate',
          value: value.endDate
        }
      ];
      this.onMultipleParametersChange(parameters);
    } else {
      this.onUniqueParameterChange(keys[key], value);
    }

    this.setState(getNewState[key](value));
    this.setState({ loading: false });
  }

  onChangeText = (value) => {
    clearTimeout(this.lastRequestId);
    this.lastRequestId = setTimeout(() => this.onUniqueParameterChange('search', value), 600);
  }

  onAutosuggestChange = (value) => {
    clearTimeout(this.lastRequestId);
    this.lastRequestId = setTimeout(() => this.onMultipleParameterChange('entityIds', value), 600);
  }


  verifyIncompatibleFilters = (selectedFilters) => {
    let invalidFilters = false;
    let subtitle;
    const waitTimeFilters = selectedFilters.filter(item => item.sectionId === 'waitTime') || [];
    const hasCompleted = selectedFilters.findIndex(item => item.id === 'completed') !== -1;

    selectedFilters.forEach((filter) => {
      if (waitTimeFilters && waitTimeFilters.length > 0 && (!hasCompleted && (filter.id === 'canceled' || filter.id === 'expired'))) {
        invalidFilters = true;
        subtitle = __('Requests that were cancelled or expired do not have waiting time');
      }
    });
    return { value: invalidFilters, subtitle };
  }

  handleSelectedFilters = (simpleFilters) => {
    const selectedFilters = [];
    if (simpleFilters) {
      simpleFilters.forEach((filterOption) => {
        if (filterOption.key === 'concierges') {
          filterOption.sections.forEach((section) => {
            section.items.forEach((filter) => {
              if (filter.selected) {
                filter.sectionId = section.sectionId;
                filter.fn = filterOption.fn;
                selectedFilters.push(filter);
              }
            });
          });
        }
      });
    }

    return { items: selectedFilters, hasInvalid: this.verifyIncompatibleFilters(selectedFilters) };
  }

  renderTable = ({ rows, loading, pagination }) => {
    const { location, params } = this.props;
    const { concierges, startDate, endDate, arrivalStatus, arrivalWaitTime } = this.state;
    const { entityIds } = qs.parse(location && location.query);

    const simpleFiltersList = [
      {
        name: __('Date'),
        key: 'date',
        date: {
          range: true,
          startDate: new Date(startDate),
          endDate: endDate ? new Date(endDate) : null,
        }
      },
      {
        name: __('Filters'),
        key: 'concierges',
        style: { width: 105 },
        icon: 'chevron down',
        sections: [{
          header: __('GATES'),
          items: concierges.map(({ value, text, selected }) => ({ id: value, title: text, selected })),
          sectionId: 'gates'
        }, {
          header: __('STATUS'),
          items: arrivalStatus.map(({ value, selected, color, text }) => ({ id: value, title: text, color, selected })),
          sectionId: 'status'
        },
        {
          header: __('WAITING TIME'),
          sectionId: 'waitTime',
          items: arrivalWaitTime.map(({ id, color, title, selected }) => ({ id, color, title, selected })),
        }]
      },
      {
        name: __('Sort'),
        key: 'order',
        icon: 'arrow down arrow up',
        removeButton: true,
        removeButtonText: __('Remove sorting')
      }
    ];

    const simpleFilters = simpleFiltersList.map(({ key, name, removeButton, removeButtonText, multipleTags, searchable, style, icon, sections, date }) => ({
      removeButton,
      removeButtonText,
      key,
      multipleTags,
      searchable,
      style,
      icon,
      sections,
      date,
      placeholder: name,
      options: this.state[key],
      fn: value => this.simpleFiltersCallback(value),
    }));

    const selectedFilters = this.handleSelectedFilters(simpleFilters);

    const emptyImage = selectedFilters.items.length || (entityIds && entityIds.length) ?
      {
        url: '/images/request_not_found_empty_state.svg',
        title: <span style={{ fontWeight: 500, fontSize: 20 }}>{__('No results found')}</span>,
        subtitle: <span style={{ fontSize: 14, color: '#00000099' }}>{__('Try again using other search parameters')}</span>
      }
      :
      {
        url: '/images/ilustracoes-49.svg',
        title: <span style={{ fontWeight: 500, fontSize: 20 }}>{__('No data for this date')}</span>,
        subtitle: <span style={{ fontSize: 14, color: '#00000099' }}>{__('We are waiting for the end of the school day')}</span>
      };

    // TODO - export link
    // const exportLink = (
    //   <a
    //     href="https://www.test.com"
    //     rel="noopener noreferrer"
    //     target="_blank"
    //     style={{
    //       color: 'inherit',
    //       height: '100%',
    //       display: 'flex',
    //       justifyContent: 'center',
    //       alignItems: 'center',
    //       marginLeft: '5px',
    //       border: '1px solid #e8e8e8',
    //       borderRadius: '200px',
    //       padding: '10px 16px'
    //     }}
    //   >
    //     {__('Export')}
    //   </a >
    // );

    return (
      <div>
        <Table
          style={{ minHeight: 400, paddingBottom: 30 }}
          colNames={[...this.colNames]}
          rows={rows}
          multipleAutosuggest={{
            api: {
              fn: value => this.onAutosuggestChange(value)
            },
            props: {
              fillAllWidth: this.isMobile(),
              placeholder: __('Search by student...'),
              requests: [organizationEntitySearchQuery],
              requestArguments: [{ id: params.organization_id, type: ['STUDENT'] }]
            }
          }}
          simpleFilters={this.isMobile() ? null : simpleFilters}
          selectedFilters={selectedFilters}
          invalidFilters={selectedFilters.hasInvalid}
          // customFilter={exportLink}
          loading={loading}
          gridColumns={'120fr 227fr 173fr 235fr 141fr 140fr 103fr'}
          location={location}
          emptyImage={emptyImage}
        />
        <Paginator
          isMobile={this.isMobile}
          pagination={pagination}
          onLoadMore={this.eventReload}
        />
      </div>
    );
  };

  renderDate = ({ startDate }) => {
    const { store } = this.props;
    let format = 'L';
    if (store.app.locale === 'es') format = 'DD/MM/YYYY';
    if (['ja', 'zh'].includes(store.app.locale)) format = 'LL';

    return <span key={1}>{utils.simpleDate(startDate, null, format, store.app.locale)}</span>;
  }

  renderEntityFullname = ({ fullname, style, to }) => <Link to={to}><span style={style}>{fullname}</span></Link>;

  renderFullname = ({ entity }) => (
    <div key={2} style={{ display: 'flex', height: '50px' }}>
      <div style={{ display: 'flex', width: '50px', height: '100%', alignItems: 'center' }}>
        <Avatar style={{ width: '40px', height: '40px', minWidth: 40 }} avatar src={entity.picture && entity.picture.uri} alt={entity.fullname || ''} verticalAlign="middle" />
      </div>

      <div style={{ paddingLeft: '1rem', display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center' }}>
        {entity.fullname.length > 18 ?
          <Popup
            trigger={
              this.renderEntityFullname({
                to: `/entities/${entity.id}`,
                fullname: entity.fullname.substring(0, 18).trim() + '...',
                style: {
                  display: 'inline-block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '70%',
                },
              })
            }
            content={entity.fullname}
          />
          :
          this.renderEntityFullname({
            to: `/entities/${entity.id}`,
            fullname: entity.fullname,
          })
        }
      </div>
    </div>
  )

  renderWaitingTimeLabel = ({ status, startDate, endDate }) => {
    if (status === 'CANCELED') {
      return (
        <span style={{ color: '#E5A899', fontSize: 16, display: 'flex', justifyContent: 'center', minWidth: 103 }}>
          {__('Cancelled')}
        </span>
      );
    }
    if (status === 'EXPIRED') {
      return (
        <span style={{ color: '#999999', fontSize: 16, display: 'flex', justifyContent: 'center', minWidth: 103 }}>
          {__('Expired')}
        </span>
      );
    }
    const labelStyles = {
      borderRadius: '6px',
      color: '#F9FAFB',
      fontSize: '16px',
      lineHeight: '25px',
      height: '25px',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      whiteSpace: 'nowrap',
      minWidth: '103px'
    };

    const makeLabelColor = () => {
      const hourDiff = moment(endDate).hours() - moment(startDate).hours();
      const minuteDiff = moment(endDate).minute() - moment(startDate).minute();
      const secondDiff = moment(endDate).second() - moment(startDate).second();
      let totalMinuteDiff = (hourDiff * 60) + minuteDiff;

      if (secondDiff < 0) {
        totalMinuteDiff--;
      }

      return totalMinuteDiff < 5 ? '#74B816' : totalMinuteDiff < 10 ? '#F2C617' : '#DB5D43';
    };
    const labelColor = makeLabelColor();

    const makeTimeText = function () {
      const startDateRounded = moment(startDate).set('second', 0);
      const endDateRounded = moment(endDate).set('second', 0);
      let diffTime = moment(endDateRounded).diff(moment(startDateRounded));
      let waitTime = moment.duration(diffTime);
      let hours = waitTime.hours();
      let minutes = waitTime.minutes();
      return String(`${hours > 0 ? hours < 10 ? '0' + hours + ':' : hours + ':' : ''}${hours > 0 && minutes < 10 ? '0' + minutes : minutes}`);
    };

    return (
      <Popup
        key={2}
        trigger={
          <div key={7} style={{ ...labelStyles, background: labelColor }}>
            {`${moment(startDate).format('HH:mm')} - ${moment(endDate).format('HH:mm')}`}
          </div>
        }
        content={<span style={{ color: labelColor }}>{__('≈ %s min. waiting', makeTimeText())}</span>}
      />
    );
  }


  renderResponsible = ({ user }) => {
    const [firstTag, ...otherTags] = user.tags.nodes;
    return (
      <div key={4} style={{ height: '50px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <div style={{ display: 'flex' }}>
          {firstTag &&
            <span
              key={firstTag.id}
              style={{
                height: 'fit-content',
                border: '1px solid rgba(0, 0, 0, 0.1)',
                borderRadius: '6px',
                width: 'fit-content',
                padding: '4px 8px'
              }}
            >
              {utils.renderLongText(firstTag.name, 7)}
            </span>}
          {otherTags.length > 0 &&
            <Popup
              key={2}
              trigger={
                <div
                  style={{
                    marginLeft: '0.5rem',
                    height: 'fit-content',
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    borderRadius: '6px',
                    width: 'fit-content',
                    padding: '4px 8px'
                  }}
                >
                  +{otherTags.length}
                </div>
              }
              content={otherTags.map((t, i) => <span key={t.id}>{(i == otherTags.length - 1 ? `${t.name}` : `${t.name}, `)}</span>)}
            />}
        </div>
        <span
          style={{
            color: '#00000099',
            marginTop: '10px',
            whiteSpace: 'nowrap'
          }}
        >{utils.renderLongText(user.fullname, 14)}</span>
      </div>
    );
  }

  renderEntrance = ({ concierge }) => <span key={6}>{concierge.name}</span>

  renderHandledBy = ({ acceptedUser, concludedUser, status }) => {
    /*
      Cases:
        COMPLETED
          1) By Staff -> no Popup, show name (has concludedUser)
          By parent
            2) Staff accepted -> no Popup, show name (has acceptedUser)
            3) Staff didn't accept -> no Popup, no name
        CANCELED
          4) By Staff -> no Popup, show name  (has concludedUser)
          5) By parent -> with Popup, no name
        EXPIRED
          6) Staff accepted -> no Popup, show name (has acceptedUser)
          7) Staff didn't accept -> with Popup, no name
    */
    const userLabel = user => (
      <span
        key={user.id}
        style={{
          height: 'fit-content',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          borderRadius: '200px',
          width: 'fit-content',
          padding: '12px 14px 12px 14px'
        }}
      >
        {utils.renderLongText(user.fullname, 7)}
      </span>
    );

    const wrap = component => (
      <div key={4} style={{ height: 'fit-content', display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'fit-content' }}>
        {component}
      </div>
    );

    // Cases 1, 2, 4 and 6
    if (concludedUser || acceptedUser) return wrap(userLabel(concludedUser || acceptedUser));

    const centralizedSpan = <span style={{ textAlign: 'left', width: 'fit-content' }}> -- </span>;
    // Case 3
    if (status === 'COMPLETED') return wrap(centralizedSpan);

    // Cases 5 and 7
    const popupContent = __(status === 'CANCELED' ?
      'This request was cancelled by the responsible party'
      : 'This request expired without being updated');

    return (
      <Popup
        key={3}
        position="top center"
        trigger={wrap(centralizedSpan)}
        content={popupContent}
        hideOnScroll
      />
    );
  }

  setConciergesFilters = (query) => {
    const { data } = this.state;
    let conciergeIds = [];
    if (query.conciergeIds) {
      conciergeIds = JSON.parse(query.conciergeIds);
    }
    const { nodes } = data.node.arrivedConfiguration.concierges;

    let concierges = [...nodes.map(c => ({ value: c.id, text: c.name, selected: conciergeIds.includes(c.id) }))];
    this.setState({ concierges });
  }

  setWaitTimeFilters = (query) => {
    let duration = [];
    if (query.duration) {
      duration = JSON.parse(query.duration);
    }
    let arrivalWaitTime = this.state.arrivalWaitTime.map(waitTimeOption => ({ id: waitTimeOption.id, color: waitTimeOption.color, title: waitTimeOption.title, selected: duration.includes(waitTimeOption.id) }));
    this.setState({ arrivalWaitTime });
  }


  setStatusFilters = (query) => {
    let status = [];
    if (query.status) {
      status = JSON.parse(query.status);
    }
    let arrivalStatus = this.state.arrivalStatus.map(statusItem => ({ value: statusItem.value, text: statusItem.text, color: statusItem.color, selected: status.includes(statusItem.value.toUpperCase()) }));
    this.setState({ arrivalStatus });
  }

  renderTransportation = ({ vehicle }) => {
    const { licensePlate, brand, model } = vehicle || {};

    let vehicleInfo = '';
    if (brand || model) {
      let details = '';
      if (brand) details += brand + ' ';
      if (model) details += model;
      vehicleInfo = utils.renderLongText(details.trim(), 10);
    }

    return (<div
      key={5}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <span>
        <Icon name={vehicle ? 'car' : 'walking'} style={{ fontWeight: vehicle ? '500' : '300', marginRight: 12 }} />
        {vehicle ? __('Vehicle') : __('On foot')}
      </span>
      {
        vehicle &&
        <span style={{ color: '#00000099', marginTop: '10px' }}>
          {licensePlate ? `[${licensePlate}]` : null} {vehicleInfo}
        </span>
      }
    </div>);
  }


  handleQueryOptions = () => {
    const { location, params: { organization_id } } = this.props;
    const { conciergeIds, endDate, entityIds, limit, orderBy, p, startDate, status = ['COMPLETED', 'CANCELED', 'EXPIRED'], duration } = location.query;
    let newDuration;
    let newStatus;
    if (duration) {
      newDuration = handleDuration(JSON.parse(duration));
      newStatus = ['COMPLETED'];
    }

    return {

      organizationId: organization_id,
      limit: parseInt(limit || PAGE_SIZE, 10),
      offset: parseInt(((p || 1) - 1) * (limit || PAGE_SIZE), 10),
      orderBy: (orderBy && orderBy !== 'clear-all' && [JSON.parse(orderBy)]) || { column: 'CREATED', direction: 'DESC' },
      dateInterval: getDateInterval(startDate, endDate) || null,
      conciergeIds: (conciergeIds && JSON.parse(conciergeIds)) || null,
      entityIds: (entityIds && JSON.parse(entityIds)) || null,
      status: (duration && newDuration && newStatus) ? newStatus : typeof (status) === 'string' ? JSON.parse(status) : status,
      duration: (duration && newDuration) || null

    };
  }

  componentDidMount() {
    const { client } = this.props;
    client.query({ query: OrganizationArrivedQuery, variables: this.handleQueryOptions(), fetchPolicy: 'no-cache' })
      .then((data) => {
        if (data) {
          data.node = data.data.node;
          delete data.data;
        }


        this.setState({ data, isLoading: false });
      }).catch((e) => {
        console.error(e);
        this.setState({ isLoading: false });
      });
  }

  componentDidUpdate(prevProps) {
    const { client } = this.props;
    if (prevProps && this.props.location && prevProps.location.search !== this.props.location.search) {
      client.query({ query: OrganizationArrivedQuery, variables: this.handleQueryOptions(), fetchPolicy: 'no-cache' })
        .then((data) => {
          if (data) {
            data.node = data.data.node;
            delete data.data;
          }


          this.setState({ data, isLoading: false });
        }).catch((e) => {
          console.error(e);
          this.setState({ isLoading: false });
        });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location: { search } } = this.props;
    const { location: { search: nextSearch, query: nextQuery } } = nextProps;
    if (search !== nextSearch) {
      const { startDate, endDate } = this.getDatesFromQuery(nextQuery);
      const order = this.getOrderFromQuery(nextQuery);
      const conciergeIds = this.getConciergeIdsFromQuery(nextQuery);
      this.setConciergesFilters(nextQuery);
      this.setStatusFilters(nextQuery);
      this.setWaitTimeFilters(nextQuery);
      this.setState({ startDate, endDate, order, conciergeIds });
    }
  }

  render() {
    const { location } = this.props;
    const { data } = this.state;
    if (!data || ((data.loading && !data.node) || !data.node)) return <Loader active inline="centered" />;
    const { arrivals } = data.node;
    const { nodes, totalCount } = arrivals;

    if (data.node.arrivedConfiguration
      && data.node.arrivedConfiguration.concierges
      && data.node.arrivedConfiguration.concierges.nodes
      && data.node.arrivedConfiguration.concierges.nodes.length > 0
      && this.state.concierges.length === 0) {
      this.setConciergesFilters(location.query);
      this.setStatusFilters(location.query);
      this.setWaitTimeFilters(location.query);
    }


    const preparedArrivals = nodes.map(a => [
      { value: this.renderDate(a) },
      { value: this.renderFullname(a) },
      { value: this.renderResponsible(a) },
      { value: this.renderTransportation(a) },
      { value: this.renderEntrance(a) },
      { value: this.renderHandledBy(a) },
      { value: this.renderWaitingTimeLabel(a) }
    ]);

    return (
      <div id="OrganizationHistory" className="scrollable-table">
        <div style={{ display: 'flex' }}>
          <div
            style={{
              width: '100%'
            }}
          >
            <div>
              {
                this.renderTable({
                  rows: preparedArrivals,
                  loading: data.loading,
                  pagination: {
                    loading: false,
                    totalCount,
                    query: location.query
                  }
                })
              }
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
