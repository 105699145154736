import React from 'react';
import { inject, observer } from 'mobx-react';
import { Header, Checkbox, Icon, Popup } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import InputMask from 'react-input-mask';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';


import Form from '../../components/Form';
import ConciergeManager from '../Concierge/Add';
import SelectUser from '../../components/ui/SelectUser';

import { __ } from '../../i18n';
import * as utils from '../../utils';

@inject('store')
@observer
export default class ArrivedForm extends Form {
  componentDidUpdate = () => {
    const hasChanges = this.hasChanges();
    this.updateSuper(hasChanges);
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const hasPropsValuesChanged = !isEqual(nextProps.values, this.props.values);
    if (hasPropsValuesChanged) this.setState({ values: { ...nextProps.values } });
    else if (nextProps.errors) {
      this.setState(prevState => ({
        errors: nextProps.errors,
        values: { ...prevState.values, endDate: null }
      }));
    }
  }

  defaultValues = {
    enabled: false,
    selectedTags: [],
    staffResponsible: [],
    concierges: []
  }

  tooltipContent = {
    tags: {
      text: __('Select tags of the responsible persons who will have access to the functionality. Tags such as "Student" or similar **should not be marked**')
    },
    staffResponsible: {
      text: __('Select the staff members who will be in charge of managing the arrival and departure of the students')
    },
    concierges: {
      text: __('Create gates that represent the physical entrances to your school and define which students and groups will be assigned to each one')
    }
  };

  hasChanges = () => !isEqual(this.state.values, this.props.values);

  clearError(name) {
    this.setState(prevState => ({ errors: { ...prevState.errors, [name]: null } }));
  }

  getCheckboxLabel = () => (this.state.values.enabled ? __('Feature Enabled') : __('Feature Disabled'))

  updateSuper = (hasChanges) => {
    const superHasChanges = this.props.hasFormValuesChanges;
    if (hasChanges !== superHasChanges) this.props.onFormValuesChange(hasChanges);
  }

  tagIsStudent = (tag) => {
    const regExp = /student|aluno|estudiante|学生/gi;
    return regExp.test(tag.name);
  }

  renderCheckBoxTags = tags => tags.map(({ id, name }) => {
    const { errors, values } = this.state;
    const { selectedTags } = values;
    const checked = selectedTags.includes(id);
    const hasErrors = errors && errors.selectedTags && !this.tagIsStudent({ name });

    return (
      <Form.Checkbox
        key={id}
        name={name}
        label={__(name)}
        checked={checked}
        disabled={this.props.hasPendingArrival}
        disableWithoutBanIcon
        onClick={() => {
          this.clearError('selectedTags');
          return this.onInputChange(null, { name: 'selectedTags', value: checked ? selectedTags.filter(tagId => tagId !== id) : [...selectedTags, id] });
        }}
        className={hasErrors ? 'error aligned' : 'aligned'}
        style={this.isMobile() ? { marginLeft: 'unset' } : null}
      />
    );
  })

  renderTooltipPopup = (content) => (
    <Popup
      basic
      wide="very"
      trigger={<div style={{ paddingLeft: 2 }}>
        <Icon name="info circle" style={{ paddingLeft: 5, color: '#999999', fontWeight: 300 }} />
      </div>}
    >
      <Popup.Content>
        <div style={{ fontSize: 12, color: '#00000099' }}>{content}</div>
      </Popup.Content>
    </Popup>
  )

  render() {
    const { tags, hasPendingArrival, hiddenEntities, store } = this.props;
    const { values, errors } = this.state;

    const formProps = omit(this.props, 'onSubmit');
    const toggleHasNoWrap = ['ja', 'zh'].includes(store.currentUser.language);

    return (
      <Form
        id="ArrivedForm"
        onSubmit={() => {
          this.props.onSubmit(values);
        }}
        disableSubmit={hasPendingArrival}
        // style={hasPendingArrival ? { opacity: 0.5 } : null}
        className={hasPendingArrival ? 'translucent' : null}
        {...formProps}
      >
        <div className="content">
          <Form.Field className="enabled">
            <Checkbox
              toggle
              name="enabled"
              data-name="enabled"
              checked={values.enabled}
              data-value={values.enabled}
              label={this.getCheckboxLabel()}
              style={toggleHasNoWrap ? { whiteSpace: 'nowrap' } : null}
              onChange={(e, { checked }) => !hasPendingArrival && this.onInputChange(null, { name: 'enabled', checked })}
            />
          </Form.Field>
          <h3 style={{ marginTop: '2rem', display: 'flex' }}>{__('Tags')} {this.renderTooltipPopup(utils.parseFormattedTranslation({
            text: __(this.tooltipContent.tags.text),
            props: {
              style: {
                fontWeight: 700
              }
            }
          }))}</h3>
          <span style={{ marginTop: '1rem', marginBottom: '1rem' }}>{__('Grant access to ')}</span>
          <Form.Field style={this.isMobile() ? { flexDirection: 'column' } : { flexWrap: 'wrap' }}>
            {this.renderCheckBoxTags(tags)}
          </Form.Field>
          {errors && (errors.selectedTags) &&
            <div className="error-warning-red" >
              <Icon data-value="error" name="exclamation circle" />
              {__('At least one tag must be selected')}
            </div>
          }
          <h3 style={{ marginTop: '2.5rem', display: 'flex' }}>{__('Responsible employees')}{this.renderTooltipPopup(this.tooltipContent.staffResponsible.text)}</h3>
          <SelectUser
            buttonText={__('Select employees')}
            selectedItems={values.staffResponsible}
            hiddenEntities={hiddenEntities}
            error={errors && errors.staffResponsible ? {
              text: __('At least one employee must be added'),
              className: 'error-warning-red'
            } : null}
            onSubmit={(staffResponsible) => {
              this.setValue('staffResponsible', uniqBy(staffResponsible, 'address'));
              this.clearError('staffResponsible');
            }}
            onDelete={newStaffResponsible => this.setValue('staffResponsible', newStaffResponsible)}
            currentEntityId={this.props.values && this.props.values.id}
            disabled={hasPendingArrival}
            buttonStyles={{ marginBottom: '20px' }}
            notFoundMessage={__('Person not found')}
            entityType={['ADMIN', 'STAFF']}
          />
          <h3 className="concierge-title" style={{ marginTop: '2.5rem', marginBottom: '1rem', display: 'flex' }}>{__('Gates')}{this.renderTooltipPopup(this.tooltipContent.concierges.text)}</h3>
          <ConciergeManager
            concierges={values.concierges}
            error={errors && errors.concierges ? {
              text: __('At least one gate should be added'),
              className: 'error-warning-red'
            } : null}
            onChangeValues={(concierges) => {
              this.setValue('concierges', concierges);
              this.clearError('concierges');
            }}
            onDelete={newConcierges => this.setValue('concierges', newConcierges)}
            disabled={hasPendingArrival}
          />
        </div>
      </Form>
    );
  }
}
