/* eslint-disable no-undef */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Icon, Dropdown, Popup, Container, Button } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import qs from 'qs';
import { uniq } from 'lodash';

import DashOrganizationCard from '../../components/DashOrganizationCard';
import ListEntityModal from '../../components/ui/ListEntityModal';
import BarChart from './BarChart';
import { __ } from '../../i18n';
import * as utils from '../../utils';

@inject('store') @observer
@graphql(gql`query DashboardAdhesionQuery ($organizationId: ID!, $groupId: [Int!] ) {
  viewer{
    id:dbId
    oauthProviders {
  nodes {
    id
    application {
      id
      name
      clientId
    }
  }
}
}
  node(id: $organizationId) @connection(key: "Organization", filter: ["organizationId"]) {
      ... on Organization {
        id: dbId
        groups (limit: 400) {
            nodes {
              id: dbId
              name
            }
        }
        entitiesUsingArrived: entitiesWithArrived(joinedEntities:true, entityType:STUDENT, groupId:$groupId) { totalCount }
        entitiesThatCanUseArrived: entitiesWithArrived(entityType:STUDENT, groupId:$groupId ) { totalCount }
        arrivedPendingEntities: entitiesWithArrived(pendingEntities:true, entityType:STUDENT, groupId:$groupId) { totalCount }
        arrivedConfiguration {
          id: dbId
          entity {
            id: dbId
          }
          concierges {
            nodes {
              id:dbId
              groupIds
            }
          }
        }
      }
    }
  }
  `, {
  options: ownProps => {
    ownProps.location.query.group = parseInt(ownProps.location.query.group, 10);

    return {
      fetchPolicy: 'network-only',
      variables: {
        organizationId: ownProps.params.organization_id,
        groupId: ownProps.location.query.group ? [ownProps.location.query.group] : [],
        type: 'STUDENT'
      }
    };
  }
})
export default class DashboardAdhesionItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      percetage: 0,
      pendingModal: false,
    };
  }

  onParameterChange = (name, value) => {
    const parameters = { ...this.props.location.query };
    if (!value || value === 'all') {
      delete parameters[name];
    } else {
      parameters[name] = value;
    }
    this.eventReload(parameters);
  }

  eventReload = (parameters) => {
    const { router, location } = this.props;
    const params = qs.stringify(parameters);
    const pathname = location.pathname;
    if (params.length > 0) {
      router.push(pathname + '?' + params);
    } else {
      router.push(pathname);
    }
  }

  groupOptions = () => {
    const { isMobile } = this.props;
    const concierges = this.props.data.node.arrivedConfiguration.concierges.nodes;
    const groupsInConciergesArray = concierges.map(c => c.groupIds);
    const groupsInConcierges = uniq(groupsInConciergesArray.join(',').split(',')).map(id => Number(id));
    const all = { text: isMobile ? __('Groups') : __('All groups'), value: 'all' };
    const groups = this.props.data.node.groups.nodes
      .filter(group => groupsInConcierges.includes(group.id))
      .map((group) => {
        const name = group.name.length < 20 ? group.name : (<Popup
          trigger={<span>{group.name.substring(0, 20) + '...'}</span>}
          content={<span>{group.name}</span>}
          hideOnScroll
          floating
        />);
        return { text: name, value: parseInt(group.id, 10) };
      });

    return [all].concat(groups);
  }

  exportCsv() {
    const { store, data } = this.props;
    if (!data && !data.viewer && !data.viewer.oauthProviders) return;
    const lang = store.app.locale ? (store.app.locale === 'pt-BR' ? 'pt' : store.app.locale) : 'en';


    let clientId;
    data.viewer.oauthProviders.nodes.forEach((oauthProvider) => {
      if (oauthProvider.application.name === 'ClassApp Web') clientId = oauthProvider.application.clientId;
    });
    let exportLink = `${store.app.url}/csv/organization/arrived/adhesion?` +
      `organization_id=${store.currentOrganization.id}&client_id=${clientId}&access_token=${encodeURIComponent(store.access_token)}&tz_offset=${-(new Date().getTimezoneOffset())}&locale=${lang}`;
    const { group } = this.props.location.query;

    exportLink += `&group_id=${group || ''}`;

    if (!window.navigator.onLine) {
      this.props.store.snackbar = { active: true, message: __('No internet connection'), success: false };
      return;
    }

    try {
      if (exportLink) window.open(exportLink);
      setTimeout(() => {
        this.props.store.snackbar = { active: true, message: __('Data successfully exported'), success: true };
      }, 800);
    } catch (e) {
      this.props.store.snackbar = { active: true, message: __('Error while exporting data'), success: false };
    }
  }

  renderDesktopActions = () => {
    const { data, location } = this.props;
    const { group } = location.query;
    const canUseArrivedTotalCount = data.node.entitiesThatCanUseArrived.totalCount;
    const exportDisabled = canUseArrivedTotalCount === 0;
    return (
      <div>
        <span style={{ marginLeft: '10px' }}>
          <Dropdown
            data-action="select-group"
            icon="angle down"
            inline
            scrolling
            style={{ border: '1px solid #E8E8E8', padding: '12px 15px', borderRadius: '200px' }}
            options={this.groupOptions()}
            value={group || 'all'}
            onChange={(e, { value }) => this.onParameterChange('group', value)}
          />
          <Button
            style={{ backgroundColor: '#084FFF', borderRadius: '200px', color: 'white', marginLeft: '20px' }}
            onClick={() => this.exportCsv()}
            disabled={exportDisabled}
            loading={data.loading}
          >
            <Icon name="download" />
            {__('Export')}
          </Button>
        </span >
      </div >
    );
  }

  renderDesktop = () => {
    const { data, router, location } = this.props;
    const usingArrivedTotalCount = data.node.entitiesUsingArrived.totalCount;
    const canUseArrivedTotalCount = data.node.entitiesThatCanUseArrived.totalCount;
    const pendingArrivedTotalCount = data.node.arrivedPendingEntities.totalCount;
    let percentage = 0;
    if (canUseArrivedTotalCount) {
      percentage = Math.round((usingArrivedTotalCount / canUseArrivedTotalCount) * 100);
    }

    const legendColorClassName = (() => {
      if (percentage > 60) return 'green';
      if (percentage > 25) return 'yellow';
      return 'red';
    })();

    const arrivedEntityId = this.props.data.node.arrivedConfiguration.entity.id;
    const group = location.query.group;

    const openEntitiesModalCallback = (event) => {
      event.persist();
      if (this.state.pendingModal || this.state.loading || pendingArrivedTotalCount === 0) return null;
      this.setState({ pendingModal: true });
      return this.props.store.appends.push(
        <ListEntityModal
          callBack={() => this.setState({ pendingModal: false })}
          notFoundMessage={__('Person not found')}
          entityType={['STUDENT']}
          totalCount={pendingArrivedTotalCount}
          router={router}
          arrivedEntityId={arrivedEntityId}
          group={group}
        />);
    };

    let pendingContainerClassName = 'pending-container';
    if (pendingArrivedTotalCount > 0) pendingContainerClassName += ' pointer hoverable';

    const studentsTotalCountText = `${__('of ').toUpperCase()} ${canUseArrivedTotalCount} ${(canUseArrivedTotalCount > 1 ? __('students') : __('student')).toUpperCase()}`;

    return (
      <div id="ArrivedDashboardAdhesion">
        {!!data && data.node && [
          <BarChart key={1} percentage={percentage} />,
          <div key={2} className="text-content-container">
            <div className="legend-container">
              <span>{usingArrivedTotalCount}</span>
              <div>
                <i className={legendColorClassName} />
                <span>{usingArrivedTotalCount > 1 ? __('Joined') : __('Joined (sing)')}</span>
              </div>
            </div>

            <div className="adhesion-container">
              <span className="percentage">{`${percentage}%`}</span>
              <span className="total-count">{studentsTotalCountText}</span>
            </div>

            <div
              className={pendingContainerClassName}
              onClick={openEntitiesModalCallback}
            >
              <div className="data-button-container">
                <span>{pendingArrivedTotalCount}</span>
                {pendingArrivedTotalCount > 0 && <Icon name="angle right" />}
              </div>
              <div className="pending-legend-container">
                <i />
                <span>{pendingArrivedTotalCount > 1 ? __('Pending') : __('Pending (sing)')} </span>
              </div>
            </div>
          </div>
        ]}
      </div>
    );
  }

  render() {
    const { data, isMobile, store } = this.props;

    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';
    const date = new Date();
    const now = utils.simpleDate(date, true, 'LL', lang);
    const then = utils.simpleDate(date.setDate(date.getDate() - 30), true, 'LL', lang);

    let filter;
    let content;

    if (!data.node) {
      filter = null;
      content = <div id="ArrivedDashboardAdhesion" />;
      // return (
      //   <div>
      //     <span key={1} style={{ opacity: '0.4', fontSize: '13px' }}>
      //       {__('Period from %s to %s', then, now)}
      //     </span>
      //     <DashOrganizationCard
      //       id="DashboardAdhesion"
      //       key={'adhesion'}
      //       isMobile={isMobile}
      //       title={__('Adhesion')}
      //       loading={data.loading}
      //     />
      //   </div>);
    } else {
      filter = isMobile ? this.renderMobileFilter() : this.renderDesktopActions();
      content = this.renderDesktop();
    }

    return (
      <div id="ArrivedDashboard">
        <span key={1}>
          {__('Period from %s to %s', then, now)}
        </span>
        <DashOrganizationCard
          id="DashboardAdhesion"
          key={'adhesion'}
          isMobile={isMobile}
          title={__('Adhesion')}
          loading={data.loading}
          info={<span>{__('Users with access to "Arrived" who have opened, at least one request in the last 30 days')}</span>}
          filter={filter}
        >
          {content}

        </DashOrganizationCard>
      </div>
    );
  }
}
