import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router';
import ArrivedSettingsItem from '../../components/ArrivalSettingsItem';

@withRouter @inject('store', 'client')
@graphql(gql`query OrganizationArrivedQuery($organizationId:  ID!, $limit: Int, $offset: Int) {
  node(id: $organizationId) @connection(key: "Organization", filter: ["organizationId"]) {
    ... on Organization {
      id: dbId
      confArrived
      arrivedConfiguration {
        id: dbId
        canEditArrived
        concierges {
          nodes {
            id:dbId
            name
            address
            groupIds
            groups (limit: 400) {
              nodes {
                id: dbId
                name
              }
            }
          }
        }
        tags {
          nodes {
            id: dbId
          }
        }
        entity {
          id:dbId
          users(limit: $limit, offset: $offset) {
            totalCount
            nodes {
              id: dbId
              fullname
              email
              phone
              confirmEmail
              confirmPhone
            }
          }
        }
      }
    }
  }
}`, {
  options: ownProps => ({
    name: "OrganizationArrivedQuery",
    fetchPolicy: 'network-only',
    variables: {
    organizationId: ownProps.params.organization_id,
    limit: 100,
    offset: 0,
    }
    })
  })
@graphql(gql`mutation createArrivedConfiguration($input: CreateArrivedConfigurationInput!) {
  createArrivedConfiguration(input: $input) {
    clientMutationId
  }
}`, {
  name: 'createArrivedConfiguration',
  options: {
  refetchQueries: ['OrganizationArrivedQuery', 'AppQuery']
  }
  })
@graphql(gql`mutation updateArrivedConfiguration($input: UpdateArrivedConfigurationInput!) {
  updateArrivedConfiguration(input: $input) {
    clientMutationId
  }
}`, {
  name: 'updateArrivedConfiguration',
  options: {
  refetchQueries: ['OrganizationArrivedQuery', 'AppQuery']
  }
  })
@observer
export default class ArrivedSettings extends ArrivedSettingsItem {
  constructor(props) {
    super(props);
  }
};
