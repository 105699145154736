import React from 'react';
import { inject, observer } from 'mobx-react';
import { Image } from 'semantic-ui-react';
import { Link } from 'react-router';

import { __ } from '../../i18n';
import { asset } from '../../utils';

import BetaButton from '../../components/ui/Button';
import Page from '../../components/Page';

@inject('store')
export default class ArrivedIntro extends Page {
  render() {
    const { store: { currentOrganization }, router } = this.props;

    return (
      <div id="ArrivedIntro">
        <Image alt={''} src={asset('/images/ilustracoes-47.svg')} style={this.isMobile() ? { width: 412, height: 253 } : { width: 515, height: 316 }} />
        <div style={this.isMobile() ? { maxWidth: 330, marginTop: 0 } : { width: 460 }}>
          <h2>
            {__('Organize student departures conveniently and safely')}
          </h2>
          <ul>
            <li>{' '}{__('Parents will be able to notify the school that they are waiting for the student with just one touch')}</li>
            <li>{' '}{__('The school will be able to organize the flow of departures according to the order of arrival of those responsible')}</li>
          </ul>
          <span>{__('This feature is already available on your plan')}</span>

          <BetaButton
            data-action="submit_and_activate"
            round
            text={__('Enable Feature')}
            onClick={() => router.push(`/organizations/${currentOrganization.id}/arrived/settings`)}
          />
        </div>
      </div >
    );
  }
}
